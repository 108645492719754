import '@yaireo/tagify/dist/tagify.css'
import Tagify from '@yaireo/tagify'

export default function initTagify () {
  // change inputs with class .tagify into badge-like inputs
  document.querySelectorAll('.tagify').forEach((el) => {
    const tagifyOptions = {}
    if (el.dataset.tagifyValidateEmail) {
      // eslint-disable-next-line require-unicode-regexp
      tagifyOptions.pattern = /^\S+@\S+\.\S+$/
    }

    // eslint-disable-next-line no-new
    new Tagify(el, tagifyOptions)
  })
}
