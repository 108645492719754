// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import '@hotwired/turbo-rails'
import 'vanilla-nested'
import initAlpine from '../js/initAlpine'
import initIntlTelInput from '../js/initIntlTelInput'
import initTagify from '../js/initTagify'
import initTippy from '../js/initTippy'

initAlpine()

document.addEventListener('turbo:load', () => {
  initTagify()
  initIntlTelInput()
  initTippy()
})
