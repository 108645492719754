import 'tippy.js/dist/tippy.css'
import tippy from 'tippy.js'

export default function initTippy () {
  const tippys = tippy('[data-tippy-content]', { duration: 200, theme: 'tailwind' })
  document.addEventListener('turbo:before-cache', () => {
    // hide tooltips before cache
    // this prevent them from being open when you navigate back
    tippys.forEach(tip => tip.hide())
  })
}
