import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'

window.Alpine = Alpine

/* eslint-disable sort-keys */
export default function initAlpine () {
  Alpine.data('transitions', () => ({
    slideRightRevertTransition: {
      'x-transition:enter' () { return 'transition ease-in-out duration-300 transform' },
      'x-transition:enter-start' () { return '-translate-x-full' },
      'x-transition:enter-end' () { return 'translate-x-0' },
      'x-transition:leave' () { return 'transition ease-in-out duration-300 transform' },
      'x-transition:leave-start' () { return 'translate-x-0' },
      'x-transition:leave-end' () { return '-translate-x-full' },
    },
    zoomUpTransition: {
      'x-transition:enter' () { return 'transition ease-out duration-300' },
      'x-transition:enter-start' () { return 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' },
      'x-transition:enter-end' () { return 'opacity-100 translate-y-0 sm:scale-100' },
      'x-transition:leave' () { return 'transition ease-in duration-200' },
      'x-transition:leave-start' () { return 'opacity-100 translate-y-0 sm:scale-100' },
      'x-transition:leave-end' () { return 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' },
    },
    fadeOpacityTransition: {
      'x-transition:enter' () { return 'transition ease-linear duration-300' },
      'x-transition:enter-start' () { return 'opacity-0' },
      'x-transition:enter-end' () { return 'opacity-100' },
      'x-transition:leave' () { return 'transition ease-linear duration-300' },
      'x-transition:leave-start' () { return 'opacity-100' },
      'x-transition:leave-end' () { return 'opacity-0' },
    },
    notificationTransition: {
      'x-transition:enter' () { return 'transition ease-out duration-300 transform' },
      'x-transition:enter-start' () { return 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2' },
      'x-transition:enter-end' () { return 'translate-y-0 opacity-100 sm:translate-x-0' },
      'x-transition:leave' () { return 'transition ease-in-out duration-300 transform' },
      'x-transition:leave-start' () { return 'opacity-100' },
      'x-transition:leave-end' () { return 'opacity-0' },
    },
  }))

  Alpine.start()
}
/* eslint-enable sort-keys */
